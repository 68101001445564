import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";

import { rhythm } from "../utils/typography";
import Layout from "../layouts/index.js";

export default ({ location }) => {
  return (
    <Layout location={location}>
      <h1>Hello world</h1>
      <p>
        I'm a full-stack software developer with 10 years prior experience in
        sales and business management. I specialize in distributed systems built
        with React and Node, and I'm a Certified Cloud Practitioner by Amazon
        Web Services.
      </p>
    </Layout>
  );
};
